import React, {
    ReactElement,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import professionalExperienceApprovalPageController from './controller/prof_exp_approval.controller';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';
import {
    TextField,
} from '@mui/material';
import {
    Heading,
    ContainerSx,
} from "../service_page/styles/landing_page.style";
import {
    ViewToggle,
    TableProfessionalExperiencesNewApproval,
    NewExpRow,
} from "./styles/landing_page.styles"
import {
    StudentRow,
    TableAllStudentOverview,
    FilterButton,
} from "./styles/student_overview.styles"

export default function LandingPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    const [expData, setExpData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [yearFilter, setYearFilter] = useState(null);
    const [lowerBound, setLowerBound] = useState(null);
    const [upperBound, setUpperBound] = useState(null);
    const [nameFilter, setNameFilter] = useState('');

    const [studentExpsData, setStudentExpsData] = useState([]);
    const [studentName, setStudentName] = useState('')

    const insertNewExperiences = async () => {
        const response = await professionalExperienceApprovalPageController.getProfExpNew();
        const dataResponse = response.data.newExperiences;
        const listData = [];
        dataResponse.forEach(exp => {
            const newRow = <NewExpRow expID={exp.id} name={exp.name} yearGrad={exp.graduation_year} unixSubmit={exp.submission_timestamp} unixEvent={exp.event_date} approvalStat={exp.approval_status} location={exp.location} description={exp.description} reflection={exp.reflection} imgPath={exp.img_path} reviewerID={exp.reviewer_uid} reviewerComment={exp.reviewer_comment} reviewerName={exp.reviewer_name} isCoChair={account.permissions == AccountPermissions.CO_CHAIR}></NewExpRow>;
            listData.push(newRow);
        });
        setExpData(listData);
    }

    const insertUserList = async (addedFilterBool, yearFilterValue, lowerBound, upperBound, nameFilterValue) => {
        const request = await professionalExperienceApprovalPageController.getStudentListProfExp();
        const dataResponse = request.data.studentData;
        const listData = [];
        dataResponse.forEach(student => {
            if (addedFilterBool) {
                if ((yearFilterValue == null || yearFilterValue == student.graduation_year) && (lowerBound == null || (lowerBound <= (((student.done_prof_exp == 0 && student.required_prof_exp == 0 ? 1 : student.done_prof_exp) / student.required_prof_exp) * 100))) && (upperBound == null || (upperBound >= (((student.done_prof_exp == 0 && student.required_prof_exp == 0 ? 1 : student.done_prof_exp) / student.required_prof_exp) * 100))) && ((nameFilterValue.length < 1) || (student.name.toLowerCase().includes(nameFilterValue.toLowerCase())))) {
                    const newRow = <StudentRow studentID={student.uid} user={student.user} name={student.name} gradYear={student.graduation_year} expReq={student.required_prof_exp} expDone={student.done_prof_exp}></StudentRow>
                    listData.push(newRow);
                }
            } else {
                const newRow = <StudentRow studentID={student.uid} name={student.name} gradYear={student.graduation_year} expReq={student.required_prof_exp} expDone={student.done_prof_exp}></StudentRow >
                listData.push(newRow);
            }
        });
        setStudentData(listData);
        setTimeout(() => {
            enableButtonPush();
        }, 250);
    }

    const setupRefreshScroll = () => {
        setTimeout(() => {
            var scrollpos = localStorage.getItem('scrollposProf');
            if (scrollpos) window.scrollTo(0, scrollpos);
            localStorage.setItem('scrollposProf', 0);
        }, 500);
    }

    const filterResults = async (yearFilterValue, lowerValue, upperValue, nameValue) => {
        setStudentData([]);
        insertUserList(true, yearFilterValue, lowerValue, upperValue, nameValue);
    }

    useEffect(() => {
        if (!account || account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH, AccountPermissions.REGULAR_STUDENT, AccountPermissions.CLASS_SERVICE_REP, AccountPermissions.SERVICE_CHAIR].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        } else {
            setupRefreshScroll();
            insertNewExperiences();
            insertUserList(false, null, null, null);
        }
    }, []);

    const enableButtonPush = () => {
        const studentButtonList = document.getElementsByClassName("studentSubEvents");
        Array.prototype.forEach.call(studentButtonList, function (btn) {
            const username = btn.id;
            btn.addEventListener("click", async () => {
                setStudentName(btn.name);
                const request = await professionalExperienceApprovalPageController.getStudentProfExp(username);
                console.log(request);
                const dataResponse = request.studentProfExps;
                const listData = [];
                dataResponse.forEach(exp => {
                    const newRow = <NewExpRow expID={exp.id} name={exp.name} yearGrad={exp.graduation_year} unixSubmit={exp.submission_timestamp} unixEvent={exp.event_date} approvalStat={exp.approval_status} location={exp.location} description={exp.description} reflection={exp.reflection} imgPath={exp.img_path} reviewerID={exp.reviewer_uid} reviewerComment={exp.reviewer_comment} reviewerName={exp.reviewer_name} newHoursTable={false} isCoChair={account.permissions == AccountPermissions.CO_CHAIR}></NewExpRow>;
                    listData.push(newRow);
                });
                setStudentExpsData(listData);
                document.getElementById("studentEventIndividual").style.display = 'flex';
                document.getElementById("studentIndividualTab").style.display = '';
                document.getElementById("studentOverview").style.display = 'none';
                document.getElementById("newExperiencesList").style.display = 'none';
            });
        });
    }

    return (
        <PageTemplate active="">
            <HeaderCard bkgSrc="/images/headers/Excitement.JPG" >
                <HeaderCard.Title>Professional Experience Approval</HeaderCard.Title>
            </HeaderCard>
            <ContainerSx style={{ paddingBottom: '0rem' }}>
                <ViewToggle
                    idAllEvents="studentOverview"
                    idNewEvents="newExperiencesList"
                    idStudent="studentEventIndividual"
                />
            </ContainerSx>
            <ContainerSx id="studentOverview" style={{ display: 'none', paddingTop: '1rem' }}>
                <Heading style={{ textAlign: 'center' }}>Student Overview</Heading>

                <table>
                    <tr>
                        <td><TextField value={yearFilter} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Year Filter" onChange={(e) => { setYearFilter((isNaN(parseInt(e.target.value))) ? null : parseInt(e.target.value)) }}>{yearFilter}</TextField></td>
                        <td><TextField value={lowerBound} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Lower Bound (%)" onChange={(e) => { setLowerBound((isNaN(parseInt(e.target.value))) ? null : parseInt(e.target.value)) }}>{lowerBound}</TextField></td>
                        <td><TextField value={upperBound} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Upper Bound (%)" onChange={(e) => { setUpperBound((isNaN(parseInt(e.target.value))) ? null : parseInt(e.target.value)) }}>{upperBound}</TextField></td>
                    </tr>
                </table>
                <hr />
                <TextField value={nameFilter} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Name Filter" onChange={(e) => { setNameFilter((e.target.value)) }}>{nameFilter}</TextField>
                <FilterButton onClick={() => { filterResults(yearFilter, lowerBound, upperBound, nameFilter) }}>Filter Results</FilterButton>
                <hr />

                <TableAllStudentOverview tableBody={studentData}></TableAllStudentOverview>
            </ContainerSx>
            <ContainerSx id="newExperiencesList" style={{ paddingTop: '1rem' }}>
                <Heading style={{ textAlign: 'center' }}>New Professional Experiences</Heading>
                <TableProfessionalExperiencesNewApproval tableBody={expData}></TableProfessionalExperiencesNewApproval>
            </ContainerSx>
            <ContainerSx id="studentEventIndividual" style={{ display: 'none', paddingTop: '1rem' }}>
                <Heading style={{ textAlign: 'center' }}>{studentName}</Heading>
                <TableProfessionalExperiencesNewApproval tableBody={studentExpsData}></TableProfessionalExperiencesNewApproval>
            </ContainerSx>
        </PageTemplate>);
}