/* eslint-disable implicit-arrow-linebreak */
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  ArrowDropDown as DropDownIcon,
  Article as ArticleIcon,
  ContactSupport as ContactSupportIcon,
  Gavel as GavelIcon,
  Home as HomeIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  School as SchoolIcon,
  Person as ProfileIcon,
  AdminPanelSettings as AdminIcon,
  PlaylistAddCheck as ServiceApprovalIcon,
  HistoryEdu as ProfExpApprovalIcon,
} from '@mui/icons-material';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from '@mui/material/styles/styled';

// Nav Bar container styles ====================================================

export const ContainerSx = styled(AppBar)``;
ContainerSx.defaultProps = {
  posiition: 'static',
};

export const InnerSx = styled(Toolbar)``;

// Nav bar inline menu styles ==================================================

export const InlineNavMenuSx = styled('div')`
  align-items: center;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px; 
  justify-content: space-between;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
  }
`;

export const InlineNavItemListSx = styled('span')`
  align-items: center;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: fit-content;
  gap: 15px;
  padding: 0px 15px 0px 15px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const InlineNavLinkSx = styled(ReactRouterLink)`
  align-items: center;
  height: fit-content;
  text-decoration: none;
`;

export const InlineNavLinkTextSx = styled(Typography)`
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  color: ${({ activeid, linkid, theme }) =>
    (linkid === activeid ? theme.palette.primary.main : 'black')}
`;

// Nav bar logo styles =========================================================

export const LogoNavLinkSx = styled(ReactRouterLink)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 10px;
  height: fit-content;
  text-decoration: none;
`;

export const LogoNavLinkImageSx = styled('img')`
  height: 45px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height:36px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    height:24px;
  }
`;

export const LogoNavLinkTextSx = styled(Typography)`
  color: black;
  font-size: 24px;
  font-weight: bold;
  height: fit-content;
  width: fit-content;
  text-align: center;

  ${(props) => props.theme.breakpoints.between('sm', 'lg')} {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size:18px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size:15px;
  }
`;
LogoNavLinkTextSx.defaultProps = {
  variant: 'h6',
};

// Nav bar account styles ======================================================

export const LoginButtonSx = styled(Button)``;
LoginButtonSx.defaultProps = {
  component: ReactRouterLink,
  variant: 'outlined',
};

export const AccountButtonSx = styled(Button)`
  align-items: center;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 5px;
  height: 45px;
`;
AccountButtonSx.defaultProps = {
  component: 'div',
  variant: 'outlined',
};

export const AccountImageSx = styled('img')`
  border-radius: 50%;
  height: 30px;
`;

export const AccountNameSx = styled(Typography)``;

export const PopoverSx = styled(Popover)``;
PopoverSx.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom', horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top', horizontal: 'right',
  },
};

export const PopoverContainerSx = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;

export const PopoverListSx = styled(List)`
  padding: 0px;
`;

export const PopoverLogoutButtonSx = styled(Button)`
  display: flex;
  text-transform: none;
  gap: 15px;
  font-size: 16px;
  font-weight: bold;
  color: black;
`;
PopoverLogoutButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const PopoverProfileButtonSx = styled(Button)`
  display: flex;
  text-transform: none;
  gap: 15px;
  font-size: 16px;
  font-weight: bold;
  color: black;
`;
PopoverProfileButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const PopoverAdminButtonSx = styled(Button)`
display: flex;
text-transform: none;
gap: 15px;
font-size: 16px;
font-weight: bold;
color: black;
`;
PopoverAdminButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const PopoverServiceApprovalButtonSx = styled(Button)`
display: flex;
text-transform: none;
gap: 15px;
font-size: 16px;
font-weight: bold;
color: black;
`;
PopoverServiceApprovalButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const DropDownIconSx = styled(DropDownIcon)``;

// Nav Bar more menu button styles =============================================

export const MoreMenuButtonSx = styled(IconButton)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const MoreMenuIconSx = styled(MenuIcon)``;

// Nav bar drawer styles =======================================================

export const DrawerSx = styled(Drawer)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const DrawerDividerSx = styled(Box)`
  border-bottom: 1px solid black;
  display:block;
  width: calc(100% - 30px);
  margin: 10px 15px;
  
`;
DrawerDividerSx.defaultProps = {
  component: 'span',
};

export const DrawerLogoutButtonSx = styled(Button)`
  font-size: 15px;
  font-weight: bold;
  color: red;
  text-transform: none;
  display: inline-flex;
  gap: 20px;
  align-items: center;
  padding: 0px;
`;
DrawerLogoutButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const DrawerProfileButtonSx = styled(Button)`
  font-size: 15px;
  font-weight: bold;
  color: red;
  text-transform: none;
  display: inline-flex;
  gap: 20px;
  align-items: center;
  padding: 0px;
`;
DrawerProfileButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const DrawerAdminButtonSx = styled(Button)`
  font-size: 15px;
  font-weight: bold;
  color: red;
  text-transform: none;
  display: inline-flex;
  gap: 20px;
  align-items: center;
  padding: 0px;
`;
DrawerAdminButtonSx.defaultProps = {
  variant: 'text',
  disableFocusRipple: true,
  disableRipple: true,
};

export const DrawerNavItemListSx = styled(List)`
  width: 80vw;
`;

export const DrawerNavItemSx = styled(ListItem)`
  width: fit-content;
`;

export const DrawerNavLinkSx = styled(ReactRouterLink)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 20px;
  height: fit-content;
  text-decoration: none;
  color: ${({ activeid, linkid, theme }) =>
    (linkid === activeid ? theme.palette.primary.main : 'black')}
`;

export const DrawerNavLinkTextSx = styled(Typography)`
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
`;

// Nav bar drawer icon styles ==================================================

export const DrawerAboutIconSx = styled(GavelIcon)``;

export const DrawerContactIconSx = styled(ContactSupportIcon)``;

export const DrawerDocumentsIconSx = styled(ArticleIcon)``;

export const DrawerResourcesIconSx = styled(ArticleIcon)``;

export const DrawerHomeIconSx = styled(HomeIcon)``;

export const DrawerLoginIconSx = styled(LoginIcon)``;

export const DrawerLogoutIconSx = styled(LogoutIcon)``;

export const DrawerProfileIconSx = styled(ProfileIcon)``;

export const DrawerAdminIconSx = styled(AdminIcon)``;

export const DrawerServiceIconSx = styled(SchoolIcon)``;

export const DrawerServiceApprovalIconSx = styled(ServiceApprovalIcon)``;

export const DrawerProfessionalExperienceApprovalIconSx = styled(ProfExpApprovalIcon)``;

