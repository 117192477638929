import React, {
    ReactElement,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import submitServicePageController from './controller/submit_service.controller';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';
import {
    Heading,
} from './styles/landing_page.style';
import {
    ColumnsSx,
    ServiceSubmitButtonSx,
    ServiceSubmitDateSx,
    ServiceSubmitDescSx,
    ServiceSubmitFormSx,
    ServiceSubmitLocationSx,
    ServiceSubmitSupervisorSx,
    ServiceSubmitTypeSx,
    FormInputLabelSx,
    BlankField,
    ServiceSubmitHoursSx,
    ImageUploadSx,
    ImageDataSx,
    ServiceSubmitErrorTextSx,
    ServiceSubmitSupervisorContactSx,
    ViewToggle,
    ContainerSx
} from './styles/submit_page.style';
import {
    MenuItem,
    Button,
    CircularProgress,
} from '@mui/material';
import {
    TableServiceHoursPast,
    TableServiceHoursRow,
} from './styles/past_service.style';

export default function ServiceSubmitPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    // Variables for form
    const [type, setType] = useState('');
    const [date, setDate] = useState('');
    const [hours, setHours] = useState('');
    const [description, setDescription] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [supContact, setSupContact] = useState('');
    const [serviceLocation, setServiceLocation] = useState('');
    const [photo, setPhoto] = useState('');
    const [photoSize, setPhotoSize] = useState('');
    const [errorText, setErrorText] = useState('');
    const [errorPastText, setErrorPastText] = useState('');

    const [chartData, setChartData] = useState([]);
    const [apprHours, setApprHours] = useState(0);
    const [requiredHours, setRequiredHours] = useState(0);

    const onServiceSubmit = (event) => {
        event.preventDefault();

        // Validate form - all inputs are in, else show modal
        if (type.toString().length < 1 || photo.length < 1 || date.toString().length < 1 || hours.length < 1 || description.length < 1 || supervisor.length < 1 || supContact.length < 1 || serviceLocation.length < 1 || photo.length < 1 || photoSize.length < 1) {
            setErrorText('Error: Please fill out all fields on the form.');
        } else if (type.toString().includes(";") || hours.toString().includes(";") || description.toString().includes(";") || supervisor.toString().includes(";") || supContact.toString().includes(";") || serviceLocation.toString().includes(";")) {
            setErrorText('Error: Don\'t include semicolons.');
        } else if (isNaN(hours)) {
            setErrorText('Error: Hours entered must be a number.');
        } else if (isNaN(new Date(date))) {
            setErrorText('Error: Invalid Date, please reenter.');
        } else if (photoSize > 5) {
            setErrorText('Error: Image File is too large. Max image size is 5 MiB.');
        } else {
            document.getElementById('loadingCircle').style.display = 'flex';
            const photoData = new FormData();
            photoData.append("file", photo);
            photoData.append("filename", `${account.username}-${Date.now()}`);
            submitServicePageController.sendHours({ type: type, date: date / 1000, hours: hours, description: description, supervisor: supervisor, supContact: supContact, serviceLocation: serviceLocation }, photoData).then(
                (response) => {
                    if (response.header.status === 1) {
                        // Success sending hours
                        document.getElementById('loadingCircle').style.display = 'none';
                        refreshAccount();
                        setType('');
                        setDate('');
                        setDescription('');
                        setErrorText('Success! You can now leave this page!');
                        setHours('');
                        setSupervisor('');
                        setSupContact('');
                        setServiceLocation('');
                        setServiceLocation('');
                        setPhoto({});
                        setErrorPastText('');
                        document.getElementById('image-button').innerText = "Upload Image *"
                        document.getElementById('imageSize').innerText = "None Selected";
                    } else {
                        // Error
                        setErrorText(response.errorMessage);
                    }
                }
            );
        }
    };

    const typeOptionList = [
        { value: 5, label: "HP 200" },
        { value: 6, label: "Internal Clarkson Service" },
        { value: 7, label: "External Service (at Home)" }
    ];

    const allBaseTypes = [
        "INVALID",
        "Honors Approved",
        "Clarkson University",
        "St. Lawrence",
        "External",
        "HP 200",
        "Internal Clarkson Service",
        "External Service (at Home)"
    ];

    const requestPastService = (tableId) => {
        // event.preventDefault();
        refreshAccount();

        return submitServicePageController.getHours(account.username, "serviceDate").then(response => {
            if (response.isSuccessful === false) {
                setErrorPastText('Error retrieving data from database. Please contact the webmaster.');
                console.log(response);
            } else {
                // Iterate through data to get what is needed
                console.log(response.serviceHoursData);
                let rspData = [];
                let hoursAppr = [0, 0, 0, 0, 0, 0, 0, 0];
                Object.values(response.serviceHoursData).forEach(hour => {
                    rspData.push(<TableServiceHoursRow date={hour.serviceDate} minReq={hour.durationRequested} minAppr={hour.durationApproved} type={allBaseTypes[hour.type]} desc={hour.description} approver={hour.approver} reason={hour.reason}></ TableServiceHoursRow >);
                    hoursAppr[hour.type] += (hour.durationApproved.hours + (hour.durationApproved.minutes / 60));
                    setRequiredHours(Number(hour.user.requiredHours));
                    hoursAppr[0] = Number(hour.user.requiredHours); // Fix for required hours
                });

                // Set data from the chart
                setChartData(rspData);

                // Set total hours stats, accounting for honors limits
                let hoursExtDone = (hoursAppr[4] + hoursAppr[7]);
                console.log(hoursExtDone);
                console.log(hoursAppr[0]);
                if (hoursExtDone > (hoursAppr[0] / 2)) {
                    hoursExtDone = (hoursAppr[0] / 2);
                }
                setApprHours(hoursExtDone + (hoursAppr[1] + hoursAppr[2] + hoursAppr[3] + hoursAppr[5] + hoursAppr[6]));
            }
        }).catch((e) => {
            setErrorPastText('Error connecting to database. Please contact the webmaster.');
            console.log(e);
        }, []);
    }

    useEffect(() => {
        if (!account || account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        } else {
            requestPastService("pastHoursTable");
        }
    }, []);

    return (
        <PageTemplate active="service">
            <HeaderCard bkgSrc="/images/headers/CommunityService.jpg">
                <HeaderCard.Title>Submit Service Hours</HeaderCard.Title>
            </HeaderCard>
            <ContainerSx style={{ paddingBottom: "0rem" }}>
                <ViewToggle idPast="pastHours" idSubmit="submitHoursForm" />
            </ContainerSx>
            <ContainerSx id="pastHours" style={{ display: "none", paddingTop: "0.25rem" }}>
                <Heading>
                    Previous Hours: {apprHours} / {requiredHours} hours
                </Heading>
                <TableServiceHoursPast
                    tableBody={chartData}
                >
                </TableServiceHoursPast>
                <ServiceSubmitErrorTextSx>{errorPastText}</ServiceSubmitErrorTextSx>
            </ContainerSx>
            <ContainerSx id="submitHoursForm" style={{ paddingTop: "10px" }}>
                {/* <Inner > */}
                <Heading style={{ textAlign: 'center' }}>
                    Fill out the below form to submit your service hours.
                </Heading>
                <ServiceSubmitFormSx onSubmit={onServiceSubmit}>
                    <FormInputLabelSx id="serviceTypeLabel">Type of Service *</FormInputLabelSx>
                    <ServiceSubmitTypeSx
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        label="Type of Service *"
                        id="serviceType"
                        name="serviceType"
                        required={true}
                    >
                        {typeOptionList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </ServiceSubmitTypeSx>
                    <ServiceSubmitDescSx
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        label="Description"
                        id="description"
                        name="description"
                        multiline={true}
                    />
                    <ColumnsSx>
                        <ServiceSubmitDateSx
                            value={date}
                            onChange={(e) => {
                                try { setDate(e._d.getTime()); }
                                catch {
                                    // Empty
                                }
                            }}
                            label="Date of Service"
                            id="date"
                            name="date"
                            helperText="MM/DD/YYYY"
                            renderInput={(params) => <BlankField {...params} />}
                        />
                        <ServiceSubmitHoursSx
                            value={hours}
                            onChange={(e) => setHours(e.target.value)}
                            label="Hours"
                            id="hours"
                            name="hours"
                        />
                    </ColumnsSx>
                    <ServiceSubmitSupervisorSx
                        value={supervisor}
                        onChange={(e) => setSupervisor(e.target.value)}
                        label="Supervisor"
                        id="supervisor"
                        name="supervisor"
                    />
                    <ServiceSubmitSupervisorContactSx
                        value={supContact}
                        onChange={(e) => setSupContact(e.target.value)}
                        label="Supervisor Contact Information"
                        id="supContact"
                        name="supContact"
                    />
                    <ServiceSubmitLocationSx
                        value={serviceLocation}
                        onChange={(e) => setServiceLocation(e.target.value)}
                        label="Location"
                        id="serviceLocation"
                        name="serviceLocation"
                    />
                    <ColumnsSx id="image-data">
                        <ImageUploadSx buttonText='Upload Image *' inputId='image-input' buttonId='image-button'
                            value={photo}
                            handleChange={(e) => {
                                setPhoto(e.target.files[0]);
                                document.getElementById('image-button').innerText = e.target.files[0].name;
                                document.getElementById('imageSize').innerText = Math.ceil(e.target.files[0].size / 1024 / 1024 * 100) / 100 + " MiB";
                                setPhotoSize(Math.ceil(e.target.files[0].size / 1024 / 1024 * 100) / 100);
                            }}
                        />
                        <ImageDataSx
                            imageSizeId='imageSize'
                        />
                    </ColumnsSx>
                    <ServiceSubmitButtonSx>Submit</ServiceSubmitButtonSx>
                    <ContainerSx id='loadingCircle' sx={{ display: 'none', paddingTop: '2rem' }}>
                        <CircularProgress />
                    </ContainerSx>
                </ServiceSubmitFormSx>
                <ServiceSubmitErrorTextSx>{errorText}</ServiceSubmitErrorTextSx>
                {/* </Inner> */}
            </ContainerSx>
        </PageTemplate>
    );
}
